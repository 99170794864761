@mixin p30-semibold {
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 2.3125rem;
  color: $n400;
}
@mixin p24-default {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  color: $n500;
}
@mixin p24-semibold {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
  color: $n500;
}
@mixin p20-default {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: $n500;
}
@mixin p20-semibold {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25rem;
  color: $n500;
}
@mixin p18-default {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.125rem;
  color: $n400;
}
@mixin p18-semibold {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.125rem;
  color: $n400;
}
@mixin p16-default {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375rem;
  color: $n400;
}
@mixin p16-semibold {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.375rem;
  color: $n400;
}
@mixin p16-semibold-muted {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.375rem;
  color: $n200;
}
@mixin p15-default {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 400;
  color: $n400;
}
@mixin p15-semibold {
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 1.375rem;
  color: $n400;
}
@mixin p15-semibold-muted {
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 1.375rem;
  color: $n200;
}
@mixin p13-default {
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.375rem;
  color: $n400;
}
@mixin p13-semibold {
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.375rem;
  color: $n400;
}
@mixin p13-semibold-muted {
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.375rem;
  color: $n200;
}
@mixin p13-muted {
  font-size: 0.8125rem;
  line-height: 1.375rem;
  color: $n200;
}

@mixin transition($type: all, $time: 0.25s) {
  -webkit-transition: $type $time ease;
  transition: $type $time ease;
}

