.app-icon-upload input {
  background-color: #ddd;
  outline: 2px dashed #efefef;
  outline-offset: -5px;
  -webkit-transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  padding: 1.5rem 25%;
  margin: 0;
  text-align: center !important;
  width: 100% !important;
  height: 4.75rem;
}

.app-icon-upload img {
  margin-left: 1rem;
  max-height: 4.75rem;
  max-width: 4.75rem;
}