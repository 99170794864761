@import '../../node_modules/bootstrap/scss/bootstrap';
@import 'colors.scss';
@import 'mixins.scss';
@import 'Base.scss';

.content.internal {
  & > .nav {
    background-color: $n400;
    display: flex;
    justify-content: space-between;

    .title {
      @include p20-semibold;
      color: $n50;
      padding: 0.8rem 1rem;
    }
    .btn {
      margin: 0.5rem;
    }
  }
  & > .body {
    margin: 1rem;
  }

  .projects-list, .countries-list {
    list-style: none;

    .table-hover tr > td {
      cursor: pointer;
    }
  }
}
