@import '../../node_modules/bootstrap/scss/bootstrap';
@import 'colors.scss';
@import 'mixins.scss';
@import 'Base.scss';

body,
html {
  background-color: $n0;
}

.content {
  height: 100%;
  width: 100%;
}

.coyo-brand {
  width: 7.75rem;
  height: 2rem;
}
