@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600');
@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:400');
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background-color: $g75;
}

body,
html,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
}

code {
  font-family: 'Source Code Pro', monospace;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
.dropdown-item {
  color: $n400;
}
body,
.dropdown-item {
  font-size: 0.9375rem;
}

/* PLACEHOLDERS */

::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
::-ms-input-placeholder,
::placeholder {
  color: $n100;
}
:-moz-placeholder,
::-moz-placeholder {
  opacity: 1;
}
