@import '../../node_modules/bootstrap/scss/bootstrap';
@import 'colors.scss';
@import 'mixins.scss';
@import 'Base.scss';

.content.external {
  height: 100%;
  min-width: 320px;
  width: 100%;
  .landing {
    height: 100%;
    width: 100%;
    &.tutorial {
      // Large devices (desktops, 992px and up)
      @media (min-width: 992px) {
        .header .coyo-brand {
          margin-left: calc((100% - 900px) / 2 + 2rem);
        }
        .body,
        .footer {
          margin: 0 auto;
          width: 900px;
        }
      }
    }
    .header {
      padding: 1.5rem 2rem;
      position: relative;

      .language-selection {
        color: $n200;
        text-transform: uppercase;
        position: absolute;
        top: 1.5rem;
        right: 2rem;

        .current {
          cursor: pointer;
          display: flex;
          margin: 0 0.5rem;

          span {
            display: inline-block;
            font-size: 0.9375rem;
            padding-top: 0.175rem;
          }

          i {
            display: inline-block;
            font-size: 1.75rem;
            padding-left: 0.5rem;
          }
        }

        .dropdown {
          @extend .hover-card;
          position: absolute;
          div {
            cursor: pointer;
            padding: 0.6875rem 1rem;
            &:not(.active):hover {
              background-color: $n35;
            }
            &.active {
              cursor: not-allowed;
              font-weight: 600;
            }
            &:not(.active) {
              color: $b400;
            }
          }
        }
      }
    }
    .body {
      color: $n600;
      min-height: calc(100% - 9.4rem);
      .headline {
        font-size: 2rem;
        font-weight: 600;
        line-height: 1;
        letter-spacing: -1.13px;
        padding: 0 2rem;
        .name {
          white-space: nowrap;
        }
      }
      &.step-1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        .app-icon img {
          width: 100%;
          height: 100%;
          max-width: 5rem;
          max-height: 5rem;
        }
        .headline {
          font-size: 1.5rem;
          letter-spacing: normal;
          margin-top: 2.5rem;
          margin-bottom: 1.25rem;
        }
        .links {
          margin-bottom: 3rem;
          .download-link {
            padding: 0.375rem 0.6rem;
            img {
              max-height: 2.75rem;
            }
          }
        }
      }
      &.step-2 {
        .headline {
          margin-top: 1.75rem;
          margin-bottom: 1rem;
        }
        .steps {
          width: 100%;
          padding: 0 2rem;
          .step {
            padding: 2rem 0;
            width: 100%;
            .data {
              width: 100%;
              .title {
                font-size: 1.375rem;
                font-weight: 600;
                line-height: 1.18;
                letter-spacing: -0.0575rem;
                padding-bottom: 0.75rem;
              }
              .text {
                font-size: 0.9375rem;
                line-height: 1.47;
                padding-bottom: 1.5rem;
              }
            }
            .image {
              text-align: center;
              width: 100%;
              &:not(.with-background) img {
                max-width: 17.5rem;
                width: 100%;
              }
              &.with-background div {
                background-color: $n35;
                display: inline-block;
                min-height: 7.75rem;
                width: 100%;
                img {
                  margin-top: 1.85rem;
                }
              }
            }
            &:first-child,
            &:nth-child(2) {
              border-bottom: 1px solid $n35;
            }
            .step-option {
              color: $b400;
              font-weight: 600;
            }
            .step-option-or {
              display: block;
              font-weight: 600;
            }
          }
          .get-voucher {
            background-color: $n35;
            border-radius: 0.25rem;
            padding: 1.5rem;
            position: relative;
            text-align: center;
            .title {
              font-size: 1.375rem;
              font-weight: 600;
              line-height: 1.55;
            }
            .country-select {
              margin-top: 1rem;
              .select-field {
                margin-top: 0.5rem;
              }
            }
            .btn {
              font-size: 0.9375rem;
              line-height: 1.6;
              margin-top: 1rem;
              padding: 0.5rem 0.75rem;
              width: 100%;
              img {
                padding-right: 0.25rem;
                position: relative;
                top: -0.1rem;
              }
            }
            .voucher {
              margin: 1.5rem auto 0.75rem;
            }
            .copied {
              margin-top: 0.5rem;
              position: absolute;
              right: 1.25rem;
              padding: 0.5rem 0.75rem;
              white-space: nowrap;
              &.failed .zmdi {
                color: $r400;
                margin-right: 0.5rem;
              }
              &.success .zmdi {
                color: $g400;
                margin-right: 0.5rem;
              }
            }
            form {
              position: absolute;
              top: -9999px;
              left: -9999px;
            }
          }
        }
        // Medium devices (tablets, 768px and up)
        @media (min-width: 768px) {
          .steps {
            .step .image.with-background {
              margin-left: 3.5rem;
              div {
                max-width: 13.5rem;
              }
            }
            .get-voucher {
              .country-select {
                margin: 1rem auto;
                width: 23.75rem;
              }
              .btn {
                width: auto;
              }
              .copied {
                bottom: 1.6rem;
                margin-left: 7.5rem;
                right: auto;
              }
            }
          }
        }
        // Large devices (desktops, 992px and up)
        @media (min-width: 992px) {
          .steps .step {
            .data {
              width: 66%;
            }
            .image {
              width: 34%;
              &.with-background {
                padding: 0 1rem;
                text-align: right;
                div {
                  text-align: center;
                }
              }
              &:not(.with-background) {
                text-align: right;
              }
            }
          }
        }
      }
    }
    .footer {
      padding: 1.5rem 2.1875rem;
      a {
        color: $n600;
        font-size: 0.875rem;
        font-weight: 600;
        padding-right: 2.5rem;
      }
    }
  }
  .voucher {
    margin: 1rem;
    max-width: 13rem;
    .voucher-font {
      font-size: 1.0625rem;
      text-transform: uppercase;
    }
  }
  .hover-card {
    border-radius: 0.25rem;
    box-shadow: 0 0.25rem 0.1875rem 0 rgba(16, 29, 48, 0.1);
    border: solid 0.0625rem $n50;
    background-color: $n0;
    display: inline-block;
  }
}
