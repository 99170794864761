$n600: #101d30;
$n500: #192638;
$n400: #202d40;
$n300: #313d4d;
$n200: #697687;
$n100: #a8b1ba;
$n75: #d7dbe0;
$n50: #ebecf0;
$n35: #f2f3f4;
$n25: #f9f9fb;
$n0: #ffffff;

$b600: #074c91;
$b500: #0060bf;
$b400: #0073e6;
$b300: #2693ff;
$b200: #73b9ff;
$b100: #cce6ff;
$b75: #ebf5ff;

$g600: #5c7d00;
$g500: #789c16;
$g400: #9bbf29;
$g300: #afd62d;
$g200: #c9ed51;
$g100: #e2ff94;
$g75: #f0ffcf;

$r600: #8c2f18;
$r500: #b33c1e;
$r400: #cc4b2b;
$r300: #e05e41;
$r200: #ff8063;
$r100: #ffc2b3;
$r75: #ffebe6;

$o600: #d47400;
$o500: #ff991f;
$o400: #ffab00;
$o300: #ffc400;
$o200: #ffe380;
$o100: #fff0b3;
$o75: #fffae6;

$p600: #7f2ad4;
$p500: #8627e6;
$p400: #9a33ff;
$p300: #b366ff;
$p200: #c182ff;
$p100: #d9b3ff;
$p75: #f2e6ff;
