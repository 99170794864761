@import 'colors';

.auth {
  background-color: $n600;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  .wrapper {
    padding: 1rem;
  }
  .auth-form {
    &.card {
      border-radius: 0;
      margin: 1rem;
    }
    .card-title {
      font-size: 22px;
      font-weight: 600;
      line-height: 1.27;
      margin: 0;
    }
    .welcome-header {
      padding: 3.5rem 1rem 0rem;
    }
    .welcome-body {
      padding: 1.5rem 1rem 2rem;
      input:not([type='radio']) {
        height: 3rem;
      }
    }
  }
  .auth-errors {
    min-height: 1.5rem;
  }
  .login-form {
    .col {
      padding: 0;
    }
    .form-group {
      margin-bottom: 0.5rem;
    }
    label {
      font-size: 0.8125rem;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: 0.2px;
      color: $n400;
    }
    .buttons {
      margin: 3rem 0 1.25rem;
      .btn {
        font-size: 15px;
        font-weight: 600;
        line-height: 1;
        padding: 0.8rem;
      }
    }
  }

  .alert {
    padding: .6rem .9rem .675rem;
    position: relative;
    i {
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    .title {
      display: inline-block;
      font-weight: 600;
      padding-left: 1.75rem;
    }
    .text {
      display: block;
      padding-top: .125rem;
      padding-left: 1.75rem;
    }
    
    &.alert-danger {
      color: $n400;
      background-color: $r200;
      border-color: $r200;
    }
    
    &.alert-warning {
      color: $n400;
      background-color: $o100;
      border-color: $o100;
    }
  }
  
}

@media (min-width: 992px) {
  .auth .auth-form.card {
    padding: 0 3rem;
    max-width: 494px;
  }
}
